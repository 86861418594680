<template>
  <div class="new-user">
    <div class="centered-container">
      <md-content v-if="!sent" class="md-elevation-3">

        <div class="title">
          <img src="@/assets/images/logo-altice-empresas-h-b.png">
          <div class="md-title">Digite o PIN</div>
          <!-- <div class="md-body-1">Confirme seu PIN</div> -->
        </div>

        <div class="form-pin">
          <md-field :class="{'md-invalid': validation.hasError('form.pin')}">
            <label>Pin</label>
            <md-input v-model="form.pin" maxlength="6" autofocus></md-input>
            <span class="md-error">{{ validation.firstError('form.pin') }}</span>
          </md-field>
          <div class="md-field md-theme-default md-has-value label-confirm" v-if="step > 0">
            <label>Confirmar seu acesso por:</label>
            <md-radio v-model="form.sender" value="email">Email</md-radio>
            <md-radio v-model="form.sender" value="sms">Telemóvel</md-radio>
          </div>
          <label v-if="step > 1">Foi enviado um PIN de confirmação para o seu {{ form.sender | sender }}</label>
          <md-field v-if="step > 1" :class="{'md-invalid': validation.hasError('form.confirm_pin')}">
            <label>Introduza o PIN de confirmação</label>
            <md-input v-model="form.confirm_pin" maxlength="6" autofocus></md-input>
            <span class="md-error">{{ validation.firstError('form.confirm_pin') }}</span>
          </md-field>
        </div>

        <div class="actions md-layout md-alignment-center-space-between">
          <md-button class="md-raised md-primary" @click="connect">{{ buttonConfirm }}</md-button>
        </div>

        <div class="loading-overlay" v-if="loading">
          <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
        </div>

      </md-content>
      <!-- <card-dialog v-if="sent" text="Enviado" subTitle="As instruções para recuperar a password foram enviadas para o seu endereço de email" icon="ok"></card-dialog> -->
    </div>
  </div>
</template>

<script>
import * as Constants from '@/store/constants'
import Auth from '@/api/services/Auth.js'
import SimpleVueValidation from 'simple-vue-validator'
import Swal from 'sweetalert2'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'NewUser',
  data () {
    return {
      loading: false,
      sent: false,
      form: {
        name: this.$route.query.data.info.name,
        email: this.$route.query.data.info.email,
        meo_id: this.$route.query.data.info.sub,
        token: this.$route.query.data.access.access_token,
        refresh_token: this.$route.query.data.access.refresh_token,
        pin: '',
        confirm_pin: '',
        sender: 'email'
      },
      step: 0,
      buttonConfirm: 'Próximo'
    }
  },
  validators: {
    'form.pin': function (value) {
      return Validator.value(value).required().maxLength(6, Constants.VALIDATE_MAXLENGTH)
    }
  },
  filters: {
    sender: function (value) {
      switch (value) {
        case 'email':
          return 'email'
        default:
          return 'telemóvel'
      }
    }
  },
  methods: {
    connect () {
      switch (this.step) {
        case 0:
          this.step = 1
          this.buttonConfirm = 'Enviar'
          break
        default:
          Auth.connectMeo(this.form).then(response => {
            this.step += 1
            this.buttonConfirm = 'Concluir'
            if (this.step > 2) {
              this.$router.push({ path: '/' })
            }
          }).catch((error) => {
            if (error.response.data.detail === 'Data not valid') {
              Swal.fire(
                'Atenção!',
                'Não foi possível enviar por telemóvel, pois o mesmo não encontra-se registado, tente por email!',
                'error'
              )
            }
          })
      }
    }
  },
  created () {
    if (!this.$route.query.data.info) {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss">
.label-confirm:after {
  background-color: rgba(0, 0, 0, 0) !important;
}
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 80px;
    }
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
